import React from "react";
import {
  IUnitResearchPortalState,
  PanelsCategory,
  TPanelsCategory,
} from "../../../schema/models/unit-research-portal.model";
import { DeviceInfo } from "../../../utils/hooks.utils";
import STHUnitPanelsMenu from "../../shared/sth-unit-panels-menu/sth-unit-panels-menu.component";
import UnitResearchPortalMap from "./unit-research-portal-map/unit-research-portal-map.component";
import USA from "@svg-maps/usa";
import "react-svg-map/lib/index.css";
import "../../../styles/tailwind.scss";
import _ from "lodash";
import "./unit-research-portal-content.component.scss";

interface IUnitResearchPortalContentProps {
  data: IUnitResearchPortalState;
  deviceInfo: DeviceInfo;
  onUpdatePortalState: (
    updatedUnitResearchPortalState?: TPanelsCategory
  ) => void;
}
interface IUnitResearchPortalContentState {
  states;
  selectedState: TPanelsCategory;
  visible: boolean;
}

export default class UnitResearchPortalContent extends React.Component<
  IUnitResearchPortalContentProps,
  IUnitResearchPortalContentState
> {
  constructor(props: IUnitResearchPortalContentProps) {
    super(props);
    this.state = {
      selectedState: props.data.allPanelCategories[0],
      // selectedState: null,
      states: props.data.allPanelCategories,
      visible: false,
    };

    this.onStateSelectionChange = this.onStateSelectionChange.bind(this);
  }

  render() {
    const renderMobileLandscape: boolean = this.props.deviceInfo.isMobile && this.props.deviceInfo.width > this.props.deviceInfo.height;

    // Section Elements:
    const Description = (
      <>
        <h1 className="font-robotomono text-sm text-white font-bold mb-3 pt-5">
          Western Hunt Unit Profiles
        </h1>
        <p className="font-robotomono text-xs	text-white font-normal leading-snug tracking-tight mb-3">
          There's an abundance of hunting opportunities in the west,
          and Scout To Hunt has organized each one into these advantageous
          Hunt Unit Profiles to help you start and complete your preparations
          for your next big game hunt.
        </p>
        <p className="font-robotomono text-xs	text-white font-normal leading-snug tracking-tight mb-3">
          Leverage the past as you review meaningful insights from those who have hunted
          these areas before you. Review necessary details such as season dates, immersive
          geographic snapshots and aerial footage, success photos from other hunters,
          and public, private, and hunt unit land boundary data.
        </p>
        <p className="font-robotomono text-xs	text-white font-normal leading-snug tracking-tight mb-3">
          Broaden your exploration and reap the benefits of greater confidence with
          Scout To Hunt's Hunt Unit Profiles.
        </p>
        <p className="font-robotomono text-xs	text-white font-normal leading-snug tracking-tight mb-3">
          Select the state and species to view the list of units you're preparing to hunt.
        </p>
      </>
    ),
      Map = (
        <UnitResearchPortalMap
          visible={this.state.visible}
          deviceInfo={this.props.deviceInfo}
          selectedState={this.state.selectedState}
          onChange={this.onStateSelectionChange}
          customUSAMap={{
            ...USA,
            label: "Hunt Units in the US",
            locations: USA.locations
              .filter((x) =>
                this.props.data.allPanelCategories
                  .map((x) => x.panelState.abbreviation.toLowerCase())
                  .includes(x.id)
              )
              .map((x) => {
                const _state: any = x;
                const stateDate: PanelsCategory = this.props.data.allPanelCategories.find(
                  (x) => x.panelState.abbreviation.toLowerCase() == _state.id
                );
                _state.unitCount = `${stateDate.panelCount}`;
                return _state;
              }),
          }}
        />
      ),
      Menu = (
        <STHUnitPanelsMenu
          panelsCategory={this.state.selectedState}
          deviceInfo={this.props.deviceInfo}
          visible={this.state.visible}
          onClose={this.toggleVisibility}
        />
      ), Footer = (
        <div
          className={`footer-copyright flex justify-center w-full ${renderMobileLandscape ? 'landscape' : ''}`}
        >
          <p
            className={`uppercase text-center tracking-wide`}
            style={{
              fontSize: this.props.deviceInfo.height > this.props.deviceInfo.width ? this.props.deviceInfo.width >= 601 &&
                this.props.deviceInfo.width <= 1280 ? 'medium' : 'smaller' : 'medium',
              color: 'white',
              fontWeight: 'bolder',
              lineHeight: '1.0'
            }}
          >
            <a
              href="https://scouttohunt.com/"
              target="_blank"
              className="font-robotomono"
            >
              {renderMobileLandscape ? '© 2022 STH' : '© 2022 Scout To Hunt'}
            </a>
            <span> | </span>
            <a
              href="https://scouttohunt.com/privacy-policy/"
              target="_blank"
              className="font-robotomono"
            >

              {renderMobileLandscape ? 'Privacy' : 'Privacy Policy'}
            </a>
            <span> | </span>
            <a
              href="https://scouttohunt.com/terms-of-use/"
              target="_blank"
              className="font-robotomono"
            >
              {renderMobileLandscape ? 'Terms' : 'Terms of Use'}
            </a>
          </p>
        </div>
      );

    return (
      <div id="parent_window">
        {this.props.deviceInfo.height > this.props.deviceInfo.width
          ? (
            <div className="columns-1 w-full h-fit">
              <div className={`w-full ${this.props.deviceInfo.isMobile ? 'px-2' : 'px-5'}`}>{Description}</div>
              <div className="w-full">{Map}</div>
              {Menu}
              {Footer}
            </div>
          )
          : (
            <div className="h-full w-full">
              <div className="flex w-full" style={{ height: '95%', maxHeight: '95%', position: 'fixed', overflow: 'hidden' }}>
                <div className={`description w-1/3 p-5 ${this.props.deviceInfo.isMobile && this.props.deviceInfo.width > this.props.deviceInfo.height ? 'landscape' : ''}`}>{Description}</div>
                <div className="w-2/3">{Map}</div>
                {Menu}
              </div>
              <div className={`flex w-full`} style={{ position: 'absolute', bottom: '0px' }}>
                {Footer}
              </div>
            </div>
          )}
      </div>
    );
  }

  // Handlers:
  private onStateSelectionChange = (obj) => {
    this.setState({
      selectedState: this.state.states.find(
        (x) => x.panelState.abbreviation.toLowerCase() === obj.target.id
      ),
    });
    this.toggleVisibility(true);
  };

  private toggleVisibility = (shouldOpen?: boolean) => {
    this.setState({
      visible: !_.isUndefined(shouldOpen) ? shouldOpen : !this.state.visible,
    });
  };
}
