import { SEOProps } from "../shared/sth-seo/sth-seo.component";
import {
  IUnitResearchPortalState,
  IUnitResearchPortal,
} from "../../schema/models/unit-research-portal.model";
import { resolveImageAssest } from "../../utils/helper-functions.utils";
import { DeviceWindow } from "../../utils/hooks.utils";
import { TPanelsCategory } from "../../schema/models/unit-research-portal.model";
import React from "react";
import STHSEO from "../shared/sth-seo/sth-seo.component";
import UnitResearchPortalContent from "./unit-research-portal-content/unit-research-portal-content.component";
import "../../styles/tailwind.scss";

interface IUnitResearchPortalProps {
  pageContext?: IUnitResearchPortal;
}

export default class UnitResearchPortal extends React.Component<
  IUnitResearchPortalProps,
  IUnitResearchPortalState
> {
  constructor(props: IUnitResearchPortalProps) {
    super(props);
    this.state = {
      allPanelCategories: props.pageContext.panelCategories,
      title: "Western Hunt Unit Profiles",
      icon: resolveImageAssest(null).default,
      selectedPanelCategory: null,
      // selectedPanelCategory: props.pageContext.panelCategories[0],
    };
  }

  render() {
    const SEO: SEOProps = {
      title: "Scout To Hunt | Hunt Units",
      description: "Start scouting your next hunting location with Scout To Hunt Unit Profile's, get essential information on geography, season dates, previews, and much more.",
      icon: this.state.icon,
      pathname: "",
      keywords: "",
    };

    return (
      <DeviceWindow>
        {(deviceWindow) => (
          <>
            <STHSEO {...SEO} />
            <div className="w-full h-fit">
              <>
                <UnitResearchPortalContent
                  data={this.state}
                  deviceInfo={deviceWindow}
                  onUpdatePortalState={this.shouldUpdatePortalState}
                />
              </>
            </div>
          </>
        )}
      </DeviceWindow>
    );
  }

  // Handlers: 
  private shouldUpdatePortalState(selectedPanelCategory?: TPanelsCategory) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({
      selectedPanelCategory: selectedPanelCategory,
      title:
        selectedPanelCategory === null
          ? "Western Hunt Unit Profiles"
          : `${selectedPanelCategory.panelState.name} Units`,
      icon:
        selectedPanelCategory === null
          ? resolveImageAssest(null).default
          : resolveImageAssest(
            selectedPanelCategory.panelState.abbreviation,
            "svg"
          ).default,
    });
  }
}
