import React from "react";
import { SVGMap } from "react-svg-map";
import { DeviceInfo } from "../../../../utils/hooks.utils";
import {
  TPanelsCategory,
} from "../../../../schema/models/unit-research-portal.model";
import "react-svg-map/lib/index.css";
import _ from "lodash";

import "./unit-research-portal-map.component.scss";

interface IUnitResearchPortalMapProps {
  deviceInfo: DeviceInfo;
  selectedState: TPanelsCategory;
  visible: boolean;
  customUSAMap: any;
  onChange: (obj) => void;
}

interface IUnitResearchPortalMapState {
  deviceSize: TDeviceSize;
}

type TDeviceSize = {
  type: "desktop" | "mobile";
  tablet: "tablet" | "large" | "";
};

export default class UnitResearchPortalMap extends React.Component<
  IUnitResearchPortalMapProps,
  IUnitResearchPortalMapState
> {
  private customMapSVG: any;
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: {
        type:
          props.deviceInfo.width >= 1280
            ? "desktop"
            : props.deviceInfo.height > props.deviceInfo.width
              ? "mobile"
              : "desktop",
        tablet:
          props.deviceInfo.width >= 601 &&
            props.deviceInfo.width <= 1280
            ? "tablet"
            : props.deviceInfo.width >= 1280
              ? "large"
              : "",
      },
    };
    this.isLocationSelected = this.isLocationSelected.bind(this);
    this.locationAriaLabel = this.locationAriaLabel.bind(this);
  }

  componentDidMount() {
    this.initSVGUpdate();
    if (this.customMapSVG && !_.isUndefined(this.customMapSVG)) {
      this.setSVGViewBox();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IUnitResearchPortalMapProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (!_.isEqual(prevProps.selectedState, this.props.selectedState)) {
      this.initSVGUpdate();
    }
  }

  render() {
    return (
      <div
        ref={(map) => {
          this.customMapSVG = map;
        }}
        id="main_map"
        className={`main-map ${!_.isNull(this.state.deviceSize) ? `${this.state.deviceSize.type} ${this.state.deviceSize.tablet}` : ""}`}
      >
        <SVGMap
          map={this.props.customUSAMap}
          className={`svg-map ${!_.isNull(this.state.deviceSize) ? this.state.deviceSize.type : ""}`}
          locationClassName={this.getLocationClassName}
          isLocationSelected={this.isLocationSelected}
          onLocationClick={this.props.onChange}
          onClick={this.props.onChange}
          locationAriaLabel={this.locationAriaLabel}
        />
      </div>
    );
  }

  // Handlers: 
  getDeviceSize: TDeviceSize = {
    type:
      this.props.deviceInfo.width >= 1280
        ? "desktop"
        : this.props.deviceInfo.height > this.props.deviceInfo.width
          ? "mobile"
          : "desktop",
    tablet:
      this.props.deviceInfo.width >= 601 &&
        this.props.deviceInfo.width <= 1280
        ? "tablet"
        : this.props.deviceInfo.width >= 1280
          ? "large"
          : "",
  }

  private initSVGUpdate() {
    const _deviceSize = this.getDeviceSize;
    if (!_.isEqual(_deviceSize, this.state.deviceSize)) {
      this.setSVGViewBox();
      this.setState({ deviceSize: _deviceSize });
    }
    if (this.customMapSVG && !_.isUndefined(this.customMapSVG)) {
      this.appendSVGText(_deviceSize);
    }
  }

  private setSVGViewBox() {
    var elem = this.customMapSVG.getElementsByTagName("svg")[0];
    elem.setAttribute("preserveAspectRatio", "xMidYMid");
    let newRect = document.createElementNS(elem.getAttribute("xmlns"), "g");
    newRect.setAttribute(
      "transform",
      `scale(${this.state.deviceSize.type === 'desktop' ? '1.5' : this.state.deviceSize.tablet === 'tablet' ? '1.765' : '2.5'}) 
       translate(${this.state.deviceSize.type === 'desktop' ? '-200' : this.state.deviceSize.tablet === 'tablet' ? '-125, -25' : `-250, -100`})`
    );
    newRect.append(...elem.childNodes);
    elem.replaceChildren(newRect);
  }

  private appendSVGText(forDeviceSize: TDeviceSize) {
    if (forDeviceSize.type === "desktop") {
      Array.from(
        document.getElementById("main_map").getElementsByTagName("text")
      ).forEach((text) => {
        text.remove();
      });

      let _pathElem: Element = Array.from<Element>(
        this.customMapSVG.getElementsByClassName("svg-map__location")
      ).find(
        (path) =>
          path.classList[1] ===
          this.props.selectedState.panelState.abbreviation.toLowerCase()
      );
      if (_.isNull(_pathElem) === false) {
        this.addLabelText(
          _pathElem,
          _pathElem.getAttribute("name"),
          _pathElem.getAttribute("aria-label"),
          _pathElem.getAttribute("id")
        );
      }
    } else {
      Array.from(
        document.getElementById("main_map").getElementsByTagName("text")
      ).forEach((text) => {
        text.remove();
      });

      Array.from<Element>(
        this.customMapSVG.getElementsByClassName("svg-map__location")
      ).forEach((element) => {
        if (_.isNull(element) === false) {
          this.addLabelText(
            element,
            element.getAttribute("id").toUpperCase(),
            null,
            element.getAttribute("id")
          );
        }
      });
    }
  }

  private addLabelText(
    bgPath: any,
    titleText: string,
    subtitleText: string,
    uniqueId: string
  ) {
    let bbox = bgPath.getBBox();
    let x = bbox.x + bbox.width / (uniqueId == "id" ? 2.5 : 2);
    let y = bbox.y + bbox.height / 2;

    let title = document.createElementNS(bgPath.namespaceURI, "text");
    title.setAttribute("x", x);
    title.setAttribute("y", y);
    title.setAttribute("id", uniqueId);
    title.setAttribute("text-anchor", "middle");
    title.classList.add("svg-text", "title", uniqueId);
    title.textContent = titleText;
    bgPath.after(title);

    if (subtitleText && subtitleText.length > 0) {
      let subtitle = document.createElementNS(bgPath.namespaceURI, "text");
      subtitle.setAttribute("x", x);
      subtitle.setAttribute("y", y + 12);
      subtitle.setAttribute("text-anchor", "middle");
      subtitle.setAttribute("id", uniqueId);
      subtitle.classList.add("svg-text", "subtitle", uniqueId);
      subtitle.textContent = subtitleText;
      bgPath.after(subtitle);
    }
  }

  // Map Handlers:
  private getLocationClassName = (location): string => {
    return `svg-map__location ${location.id}`;
  };

  private locationAriaLabel = (location): string => {
    return `Units: ${location.unitCount}`;
  };

  private isLocationSelected = (location): Boolean =>
    this.props.selectedState &&
    this.props.selectedState.panelState.abbreviation.toLowerCase() ===
    location.id;
}
