/******************************(index.tsx)*******************************/
import React from "react";
import {formatPanelCategory} from "../utils/source-handlers.utils";
import {
  IUnitResearchPortal,
} from "../schema/models/unit-research-portal.model";
import UnitResearchPortal from "../components/unit-research-portal/unit-research-portal.component";

const Index = (data) => {
  let rootPageProps: IUnitResearchPortal = data.pageContext;

  rootPageProps = {
    panelCategories: rootPageProps.panelCategories.map((x) => formatPanelCategory(x)),
  };

  return <UnitResearchPortal pageContext={rootPageProps} />;
};

export default Index;
